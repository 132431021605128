import { FC, useContext, useLayoutEffect } from "react";
import Page from "../components/layout/Page";
import DeviceTableComp from "../components/DeviceTableComp";
import { AppContext } from "../context/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { editDevicePath, addDevicePath, isV2Api } from "../lib/helper";
import { USE_NEW_BACKEND_KEY } from "../constants";
import { useGlobalState } from "../lib/GlobalState";

const Setup: FC = (): JSX.Element => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const { retrieveDevices, searchDevices, searchText } = useGlobalState();
  const [searchParams] = useSearchParams();

  useLayoutEffect(() => {
    state.devices = [];
    if (searchText.length > 0) {
      searchDevices("");
    } else {
      retrieveDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToAddEditDevice = (id: string) => {
    const isNewBackend = isV2Api(searchParams.get(USE_NEW_BACKEND_KEY));
    id ? navigate(editDevicePath(id, isNewBackend)) : navigate(addDevicePath(isNewBackend));
  };

  return (
    <Page>
      <DeviceTableComp
        rows={state.devices}
        searchCallback={searchDevices}
        addEditDevicecallback={navigateToAddEditDevice}
      />
    </Page>
  );
};

export default Setup;
