import { FC, useEffect, useState } from "react";
import Select from "react-select";
import "../assets/css/components/react-select-search.css";
import { Check, X } from "heroicons-react";
import Modal from "./Modal";
import { useAddEditDevice } from "../lib/hooks/useAddEditDevice";
import { SIX_DIGIT_REGEXP, BRANCH_ID_REGEXP, DEVICE_TYPE_OPTIONS, Constants } from "../constants";

export interface DeviceAddModalProps {
  pageType: string;
}

export interface DeviceAddDataProps {
  modalStatus: string;
  deviceId: string;
  deviceName: string;
  notes: string;
  nodeId: string;
  branchId: string;
  branchName: string;
  branchAddress: string;
  branchPostcode: string;
  branchOrgUnitCode: string;
  isShowing: boolean;
  type: string;
  toggle: () => void;
  cancel: () => void;
  confirm: (confirmation: string) => void;
  searchBranch: () => void;
}
const LIGHT_GRAY = "#E5E7EB";
const DARK_GRAY = "#111827";

const DeviceAddModal: FC<DeviceAddModalProps> = ({ pageType }): JSX.Element => {
  const {
    onBranchIdChange,
    onDeviceIdChange,
    onDeviceNameChange,
    onNotesChange,
    onTypeChange,
    onNodeIdChange,
    handleAddDevice,
    handleEditDevice,
    deviceData,
    validationErrors,
  } = useAddEditDevice({ pageType });

  const {
    modalStatus,
    deviceId,
    deviceName,
    notes,
    nodeId,
    branchId,
    branchName,
    branchAddress,
    branchPostcode,
    branchOrgUnitCode,
    isShowing,
    type,
    toggle,
    cancel,
    confirm,
    searchBranch,
  } = deviceData;

  const [disable, setDisable] = useState(false);

  const handleButtonClk = () => {
    setDisable(true);
  };

  useEffect(() => {
    if (validationErrors.length > 0 && disable) {
      setDisable(false);
    }
  }, [validationErrors, disable]);

  useEffect(() => {
    if (disable) {
      modalStatus === "ADD" ? handleAddDevice() : handleEditDevice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disable, modalStatus]);

  const isBranchValid = (): boolean => {
    return branchId && (BRANCH_ID_REGEXP.test(branchId) || SIX_DIGIT_REGEXP.test(branchId));
  };

  const locationDetail = branchAddress ? branchAddress.split(",") : [];
  const [branchAddress1, branchAddress2, branchAddress3, branchAddress4, branchAddress5] = locationDetail;

  const customStyles = {
    // For the select itself (not the options)
    control: (styles, { isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? LIGHT_GRAY : "white",
      };
    },
    singleValue: (provided: any) => ({
      ...provided,
      color: DARK_GRAY,
    }),
  };

  return (
    <div className="">
      <dl className="flex-grow p-8 divide-y divide-gray-50">
        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Device ID{modalStatus === "ADD" && <span className="text-pol-red">*</span>}
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                disabled={modalStatus === "EDIT" ? true : false}
                value={deviceId}
                onChange={onDeviceIdChange}
                placeholder="e.g. Test-device-1234"
                data-testid="deviceId"
                type="text"
                name="deviceId"
                id="deviceId"
                className={`edit-data-input sm:text-sm ${modalStatus === "EDIT" ? "disabled" : ""}`}
              />
              {(validationErrors.includes(Constants.DEVICE_ID_MAX_CHAR_ERROR) ||
                validationErrors.includes(Constants.DEVICE_ID_MIN_CHAR_ERROR)) && (
                <div className="inline-flex mt-1">
                  <div className="ml-1 text-red-500">
                    {validationErrors.includes(Constants.DEVICE_ID_MAX_CHAR_ERROR)
                      ? Constants.DEVICE_ID_MAX_CHAR_ERROR
                      : Constants.DEVICE_ID_MIN_CHAR_ERROR}
                  </div>
                </div>
              )}
              {validationErrors.includes(Constants.DEVICE_ID_SPACE_CHAR_ERROR) && (
                <div className="inline-flex mt-1">
                  <div className="ml-1 text-red-500">{Constants.DEVICE_ID_SPACE_CHAR_ERROR}</div>
                </div>
              )}
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Device Name {<span className="text-pol-red">*</span>}</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={deviceName}
                onChange={onDeviceNameChange}
                placeholder="e.g. SPM-23 Testing"
                data-testid="deviceName"
                type="text"
                name="deviceName"
                id="deviceName"
                className="edit-data-input sm:text-sm disabled:cursor-pointer disabled:"
              />
              {(validationErrors.includes(Constants.DEVICE_NAME_MAX_CHAR_ERROR) ||
                validationErrors.includes(Constants.DEVICE_NAME_MIN_CHAR_ERROR)) && (
                <div className="inline-flex mt-1">
                  <div className="ml-1 text-red-500">
                    {validationErrors.includes(Constants.DEVICE_NAME_MAX_CHAR_ERROR)
                      ? Constants.DEVICE_NAME_MAX_CHAR_ERROR
                      : Constants.DEVICE_NAME_MIN_CHAR_ERROR}
                  </div>
                </div>
              )}
            </span>
          </dd>
        </div>
        <div className="edit-data-row sm:pt-5">
          <dt className="text-sm font-medium text-gray-700">
            Device Type {modalStatus === "ADD" && <span className="text-pol-red">*</span>}
          </dt>
          <dd className="flex mt-1 overflow-visible text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <Select
                isDisabled={modalStatus === "EDIT" ? true : false}
                onChange={onTypeChange}
                options={DEVICE_TYPE_OPTIONS}
                value={DEVICE_TYPE_OPTIONS.find((item) => item.value === type)}
                isSearchable
                maxMenuHeight={200}
                styles={customStyles}
                className={`flex-1 capitalize`}
              />
              {validationErrors.includes(Constants.DEVICE_TYPE_ERROR) && (
                <div className="inline-flex mt-1">
                  <div className="ml-1 text-red-500">{Constants.DEVICE_TYPE_ERROR}</div>
                </div>
              )}
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Node ID <span className="text-pol-red">*</span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={nodeId}
                required
                onChange={onNodeIdChange}
                placeholder="e.g. 1,2,3"
                type="text"
                name="nodeId"
                data-testid="nodeId"
                className="edit-data-input sm:text-sm"
              />
              {(validationErrors.includes(Constants.NODE_ID_MAX_CHAR_ERROR) ||
                validationErrors.includes(Constants.NODE_ID_ERROR)) && (
                <div className="inline-flex mt-1">
                  <div className="ml-1 text-red-500">
                    {validationErrors.includes(Constants.NODE_ID_MAX_CHAR_ERROR)
                      ? Constants.NODE_ID_MAX_CHAR_ERROR
                      : Constants.NODE_ID_ERROR}
                  </div>
                </div>
              )}
              {validationErrors.includes(Constants.NODE_ID_ALREADY_USED_ERROR) && (
                <div className="inline-flex mt-1">
                  <div className="ml-1 text-red-500">{Constants.NODE_ID_ALREADY_USED_ERROR}</div>
                </div>
              )}
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch ID <span className="text-pol-red">*</span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={branchId}
                required
                onChange={(e) => onBranchIdChange(e.target.value)}
                type="text"
                name="branchId"
                data-testid="branchId"
                className="edit-data-input sm:text-sm"
              />
              {(validationErrors.includes(Constants.BRANCH_ID_ERROR) ||
                validationErrors.includes(Constants.BRANCH_ID_NOT_FOUND_ERROR)) && (
                <div className="inline-flex mt-1">
                  <div className="ml-1 text-red-500">
                    {validationErrors.includes(Constants.BRANCH_ID_ERROR)
                      ? Constants.BRANCH_ID_ERROR
                      : Constants.BRANCH_ID_NOT_FOUND_ERROR}
                  </div>
                </div>
              )}
              {validationErrors.includes(Constants.BRANCH_ID_CHANGE_ERROR) && (
                <div className="inline-flex mt-1">
                  <div className="ml-1 text-red-500">{Constants.BRANCH_ID_CHANGE_ERROR}</div>
                </div>
              )}
            </span>
            <button
              type="button"
              disabled={!isBranchValid()}
              className={
                isBranchValid()
                  ? "inline-flex items-center btn btn-clear ml-4"
                  : "inline-flex items-center btn btn-disable ml-4"
              }
              onClick={(): void => searchBranch()}
            >
              Find Branch
            </button>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch Name <span className="text-pol-red"></span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={branchName}
                required
                disabled
                type="text"
                name="branchName"
                id="branchName"
                className="edit-data-input sm:text-sm disabled"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch Address Line1<span className="text-pol-red"></span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 ">
            <span className="flex-grow">
              <input
                value={branchAddress1 || ""}
                required
                disabled
                type="text"
                name="branchAddress"
                id="branchAddress"
                className="edit-data-input sm:text-sm disabled"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch Address Line2<span className="text-pol-red"></span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={branchAddress2 || ""}
                required
                disabled
                type="text"
                name="branchAddress"
                id="branchAddress"
                className="edit-data-input sm:text-sm disabled"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch Address Line3<span className="text-pol-red"></span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={branchAddress3 || ""}
                required
                disabled
                type="text"
                name="branchAddress"
                id="branchAddress"
                className="edit-data-input sm:text-sm disabled"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch Address Line4<span className="text-pol-red"></span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={branchAddress4 || ""}
                required
                disabled
                type="text"
                name="branchAddress"
                id="branchAddress"
                className="edit-data-input sm:text-sm disabled"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch Address Line5<span className="text-pol-red"></span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={branchAddress5 || ""}
                required
                disabled
                type="text"
                name="branchAddress"
                id="branchAddress"
                className="edit-data-input sm:text-sm disabled"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">
            Branch Postcode <span className="text-pol-red"></span>
          </dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={branchPostcode}
                required
                disabled
                type="text"
                name="branchPostcode"
                id="branchPostcode"
                className="edit-data-input sm:text-sm disabled"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Branch Org Unit Code</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <input
                value={branchOrgUnitCode}
                type="text"
                disabled
                name="branchOrgUnitCode"
                id="branchOrgUnitCode"
                className="edit-data-input sm:text-sm disabled"
              />
            </span>
          </dd>
        </div>

        <div className="edit-data-row">
          <dt className="text-sm font-medium text-gray-700">Device notes</dt>
          <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span className="flex-grow">
              <textarea
                value={notes}
                onChange={onNotesChange}
                placeholder="Device notes"
                data-testid="notes"
                name="notes"
                rows={4}
                id="notes"
                className="edit-data-input sm:text-sm disabled:cursor-pointer disabled:"
              />
              {validationErrors.includes(Constants.NOTES_MAX_CHAR_ERROR) && (
                <div className="inline-flex mt-1">
                  <div className="ml-1 text-red-500">{Constants.NOTES_MAX_CHAR_ERROR}</div>
                </div>
              )}
            </span>
          </dd>
        </div>
      </dl>
      <div className="flex flex-row-reverse items-end flex-grow px-4 py-3 bg-white-50 sm:px-6">
        <button onClick={() => cancel()} type="button" className="ml-4 btn btn-cancel">
          <X className="inline" size={18} /> Cancel
        </button>

        {modalStatus === "ADD" ? (
          <button onClick={() => handleButtonClk()} type="button" disabled={disable} className="ml-4 btn btn-success">
            <Check className="inline" size={18} /> Create Device
          </button>
        ) : (
          <button onClick={() => handleButtonClk()} type="button" disabled={disable} className="ml-4 btn btn-success">
            <Check className="inline" size={18} /> Update Device
          </button>
        )}
      </div>

      {/* Checks the useModal Hook to see if modal is showing */}
      {isShowing && (
        <Modal
          isShowing={isShowing}
          hide={toggle}
          confirmation={confirm}
          modalBody={<div>Are you sure you want to leave the page?</div>}
        />
      )}
    </div>
  );
};

export default DeviceAddModal;
