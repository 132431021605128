import { Context, createContext, ReactNode, useContext } from "react";
import { useDevices } from "./hooks/useDevices";

type GlobalState = {
  retrieveDevices: () => void;
  searchDevices: (query: string) => void;
  searchText: string;
};

const GlobalStateContext: Context<GlobalState> = createContext<GlobalState>({
  retrieveDevices: () => {},
  searchDevices: () => {},
  searchText: null,
});

export const useGlobalState = () => {
  const ctx = useContext(GlobalStateContext);

  if (!ctx) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider component");
  }

  return ctx;
};

export function GlobalStateProvider({ children }: { children: ReactNode }): JSX.Element {
  const { retrieveDevices, searchDevices, searchText } = useDevices();

  return (
    <GlobalStateContext.Provider
      value={{
        retrieveDevices,
        searchDevices,
        searchText,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
}
