import { API, Auth } from "aws-amplify";

export * from "@aws-amplify/ui-react";

const apiName = "api";

/**
 * Logs the current user out.
 */
const awsLogout = async () => {
  try {
    await Auth.signOut();
  } catch (e) {
    console.log(e);
  }
};

/**
 * Gets the session of current user.
 * Throws Exception if current user is not logged in.
 */
const awsSession = async () => {
  const user = await Auth.currentSession();
  const idToken = user.getIdToken();
  return {
    username: idToken.payload["cognito:username"],
    email: idToken.payload.email,
  };
};

type Methods = "get" | "post" | "put" | "del" | "patch" | "head";

interface QueryParams {
  queryStringParameters?: object;
  body?: object;
}

/**
 * Invokes an API through AWS API Gateway
 * @param method get/post/put/del/patch/head
 * @param path The request path
 * @param params Parameters of the request, in an object of key-value pairs
 * @return Returns data object from the API
 */
const awsAPI = async (method: Methods, path: string, params: object = {}) => {
  const queryParams: QueryParams = {};

  if (method === "get" || method === "del") {
    queryParams.queryStringParameters = params;
  } else {
    queryParams.body = params;
  }

  // @ts-ignore
  return await API[method](apiName, path, queryParams);
};

export { awsLogout, awsSession, awsAPI };
